<template>
  <div class="o-cities">
    <div class="o-cities__wrapper">
      <div v-if="data" class="o-cities__content">
        <div v-for="(item, key, index) in data" :key="key" class="o-cities__city city">
          <div v-if="item.soon">
            <div class="soon-text">
              <img src="/assets/backgrounds/soon-text.svg" alt="изображение текста скоро">
            </div>
          </div>
          <div v-else class="city__info">
            <div class="city__info--name">
              <NuxtLink :to="`/city/${key}`">
                <AButton
                  :size-lg="{ size: 50, line: 46 }"
                  :size-sm="{ size: 32, line: 34 }"
                  weight="extra-bold"
                  link
                >
                  {{ item.name }}
                </AButton>
              </NuxtLink>
            </div>
            <div class="city__info--place">
              <AText
                tag="p"
                :size-lg="{ size: 24, line: 24 }"
                :size-sm="{ size: 14, line: 18 }"
              >
                {{ item.dates }} / {{ item.place }}
              </AText>
            </div>
          </div>
          <div class="city__button">
            <div v-if="item.soon">
              <AButtonAnimate color="purple" :transform="isTablet" :scale="false" :width="imgWidth" :height="imgHeight">
                <AText
                  class="text"
                  tag="span"
                  :size-lg="{ size: 24, line: 24 }"
                  :size-sm="{ size: 20, line: 20 }"
                >
                  Скоро
                </AText>
              </AButtonAnimate>
            </div>

            <div v-else-if="item.soldout">
              <AButtonAnimate color="white" :transform="isTablet" :scale="false" :width="imgWidth" :height="imgHeight">
                <AText
                  class="text"
                  tag="span"
                  :size-lg="{ size: 24, line: 24 }"
                  :size-sm="{ size: 20, line: 20 }"
                >
                  SOLD OUT
                </AText>
              </AButtonAnimate>
            </div>

            <button
              v-else
              @click="handleClick(item)"
            >
              <AButtonAnimate :transform="isTablet" :width="imgWidth" :height="imgHeight">
                <AText
                  class="text"
                  tag="span"
                  :size-lg="{ size: 24, line: 24 }"
                  :size-sm="{ size: 20, line: 20 }"
                >
                  Купить билеты
                </AText>
              </AButtonAnimate>
            </button>
          </div>
        </div>
      </div>

      <div v-else class="o-cities__spinner">
        <img src="assets/icons/spinner.svg" alt="spinner">
      </div>
    </div>

    <MAnimation class="o-cities__animation" hide-pink />
  </div>
  <WidgetShowGoGo :url="modalUrl" v-model:is-open="isModalOpen" />
</template>

<script setup lang="ts">

import AText from '~/components/atoms/text/a-text.vue';
import AButton from '~/components/atoms/button/a-button.vue';
import type { ICitiesResponse } from '~/types/api';
import AButtonAnimate from '~/components/atoms/button-animate/a-button-animate.vue';
import WidgetShowGoGo from '~/components/molecules/tickets-widget/tickets-widget.vue';
import MAnimation from '~/components/molecules/animation/m-animation.vue';
import { isModalOpen, modalUrl, openWidget } from '~/composables/useWidget';
import { sendAnalytics } from '~/utils/useAnalytics';
import { CITY_MAP_REVERSE } from '~/constants';


interface Props {
  data?: ICitiesResponse,
}

const props = defineProps<Props>();

const device = useDevice();
const isTablet = computed(() => device.isPortraitTablet.value || device.isMobile.value);

const imgWidth = computed(() => isTablet.value ? 310 : 310);
const imgHeight = computed(() => isTablet.value ? 100 : 75);

const handleClick = (item: {
  name: string;
  ticket_href: string; 
}) => {
  sendAnalytics('tickets_' + CITY_MAP_REVERSE[item.name]);
  openWidget(item.ticket_href);
}
</script>

<style scoped lang="postcss" src="./style.css" />
