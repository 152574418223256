<template>
  <div
    class="a-button-animate"
    :style="{ width: `${width}px`, height: `${height}px`, 'cursor': scale ? 'pointer' : 'default' }"
  >
    <slot class="text" />
  </div>
</template>

<script setup lang="ts">
interface Props {
  width?: number,
  height?: number,
  color?: 'pink' | 'white' | 'purple',
  scale?: boolean,
  transform?: boolean,
}

const props = withDefaults(defineProps<Props>(), {
  width: 310,
  height: 75,
  color: 'pink',
  scale: true,
  transform: false,
});

const colorBg = computed(() => {
  switch (props.color) {
    case 'pink':
      return 'linear-gradient(to right, rgba(255, 182, 193, 0) 0%, var(--pink-color) 50%, rgba(255, 182, 193, 0) 100%)';
    case 'white':
      return 'linear-gradient(to right, rgba(255, 182, 193, 0) 0%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 182, 193, 0) 100%)';
    case 'purple':
      return 'linear-gradient(to right, rgba(255, 182, 193, 0) 0%, rgb(120, 12, 255, 0.3) 50%, rgba(255, 182, 193, 0) 100%)';
    default:
      return 'linear-gradient(to right, rgba(255, 182, 193, 0) 0%, var(--pink-color) 50%, rgba(255, 182, 193, 0) 100%)';
  }
});

const colorAnimate = computed(() => {
  switch (props.color) {
    case 'pink':
      return 'linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgb(255, 32, 150) 50%, rgba(255, 255, 255, 0) 100%)';
    case 'white':
      return 'linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0) 100%)';
    case 'purple':
      return 'linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgb(120, 12, 255, 0.4) 50%, rgba(255, 255, 255, 0) 100%)';
    default:
      return 'linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgb(255, 32, 150) 50%, rgba(255, 255, 255, 0) 100%)';
  }
});

const scaleHover = computed(() => {
  if (props.scale) {
    return 'scale(1.05)';
  }
  return 'scale(1)';
});

const scaleActive = computed(() => {
  if (props.scale) {
    return 'scale(0.95)';
  }
  return 'scale(1)';
});

const path = computed(() => {
  if (props.transform) {
    return `path('M1,10 Q155,${props.height / 2.5} 310,10 L310,66 Q155,${props.height} 0,65 Z')`;
  }

  return '';
});
</script>

<style scoped lang="postcss">
.a-button-animate {
  background: v-bind(colorBg);
  position: relative;
  text-align: center;
  overflow: hidden;
  transition: transform 0.3s ease;
  clip-path: v-bind(path);
  color: var(--white-color);

  &:hover {
    transform: v-bind(scaleHover);
  }

  &:active {
    transform: v-bind(scaleActive);
  }
}

@media screen and (min-width: 767px) {
  .a-button-animate::after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 70%;
    height: 100%;
    background: v-bind(colorAnimate);
    animation: shimmer 2s infinite ease-in-out;
    transform: translateX(-50%);
  }
}

:deep(.text) {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

@keyframes shimmer {
  0% {
    opacity: 0.1;
    left: 25%;
  }
  50% {
    opacity: 1;
  }
  100% {
    left: 75%;
    opacity: 0.1;
  }
}
</style>
