<template>
  <div class="index-page">
    <main>
      <section class="index-page__hero">
        <OMainHero />
      </section>
      <section id="tickets" ref="ticketsSection" class="index-page__cities">
        <OCities :data="commonStore.cities" />
      </section>
      <div class="headliners-community-wrapper">
        <ClientOnly>
          <video
            class="headliners-community-wrapper__video"
            muted
            playsinline
            autoplay
            loop
            aria-hidden="true"
            role="presentation"
            fetchpriority="low"
            poster="/assets/posters/2.webp"
          >
            <source src="/assets/videos/2.mp4" type="video/mp4">
            <source src="/assets/videos/2.webm" type="video/webm">
          </video>
        </ClientOnly>
        <section class="index-page__headliners">
          <OHeadliners :data="headlinersList" />
        </section>
        <section class="index-page__community">
          <OCommunity />
        </section>
      </div>
      <section class="index-page__broadcast">
        <OBroadcast />
      </section>
      <section class="index-page__falling-blocks">
        <MFallingBlocks :shapes="fallingBlockShapes" />
      </section>
      <section class="index-page__app-links">
        <OAppLinks />
      </section>
    </main>
    <OFooter />

    <div class="modals">
      <NuxtPage
        page-key="modals"
        :keepalive="true"
      />
    </div>

    <Transition name="fade">
      <MMainTicketsBtn
        :key="showButton"
        :show-button="showButton"
        @click="mainTicketsBtnHandler"
      />
    </Transition>
  </div>
</template>

<script setup lang="ts">
import OMainHero from '~/components/organisms/main-hero/o-main-hero.vue';
import OCities from '~/components/organisms/cities/o-cities.vue';
import OHeadliners from '~/components/organisms/headliners/o-headliners.vue';
import OCommunity from '~/components/organisms/community/o-community.vue';
import OBroadcast from '~/components/organisms/broadcast/o-broadcast.vue';
import { type Shape } from '~/components/molecules/falling-blocks/m-falling-blocks.vue';
import OFooter from '~/components/organisms/footer/o-footer.vue';
import useCommonStore from '~/store/common';
import MMainTicketsBtn from '~/components/molecules/main-tickets-btn/m-main-tickets-btn.vue';
import { sendAnalytics } from '~/utils/useAnalytics';
import OAppLinks from '~/components/organisms/app-links/o-app-links.vue';

const MFallingBlocks = defineAsyncComponent(() => import('~/components/molecules/falling-blocks/m-falling-blocks.vue'));

const commonStore = useCommonStore();
const ticketsSection = ref(null);
const showButton = ref(false);

const headlinersList = computed(() => commonStore.headliners);

const fallingBlockShapes = [
  { width: 214, height: 208, img: '/assets/falling-blocks/22.svg', url: '', tooltipText: 'Детская зона с играми, мастер-классами и другими развлечениями' },
  { width: 202, height: 226, img: '/assets/falling-blocks/10.svg', url: '', tooltipText: 'Зрелищное шоу с яркими образами из разных вселенных' },
  { width: 328, height: 210, img: '/assets/falling-blocks/18.svg', url: '', tooltipText: 'Самый классный мерч фестиваля — только здесь' },
  { width: 213, height: 221, img: '/assets/falling-blocks/1.svg', url: '', tooltipText: 'Огромный выбор еды и напитков на любой вкус' },
  { width: 216, height: 216, img: '/assets/falling-blocks/16.svg', url: '', tooltipText: 'Топовые клубы, встречи со звездами и зрелищные контесты' },
  { width: 209, height: 258, img: '/assets/falling-blocks/7.svg', url: '', tooltipText: 'Крутейшие зоны от партнеров фестиваля' },
  { width: 255, height: 216, img: '/assets/falling-blocks/8.svg', url: '', tooltipText: 'Все твои любимые инфлюенсеры!' },
  { width: 203, height: 235, img: '/assets/falling-blocks/3.svg', url: '', tooltipText: 'Здесь роботы, AR/VR-стенды и увлекательные эксперименты' },
  { width: 389, height: 170, img: '/assets/falling-blocks/9.svg', url: '', tooltipText: 'Рисуй, лепи, создавай украшения, выступай, твори!' },
  { width: 247, height: 174, img: '/assets/falling-blocks/4.svg', url: '', tooltipText: 'Турниры по популярным играм, консоли и симуляторы' },
  { width: 162, height: 169, img: '/assets/falling-blocks/5.svg', url: '', tooltipText: 'Зоны главных продуктов VK — всё, что ты любишь онлайн приходит в оффлайн!' },
  { width: 242, height: 192, img: '/assets/falling-blocks/13.svg', url: '', tooltipText: 'Мы за раздельный сбор и осознанное потребление' },
  { width: 367, height: 170, img: '/assets/falling-blocks/21.svg', url: '', tooltipText: 'Сцена, где артисты и селебрити выступают, делятся инсайтами и дают эксклюзивные интервью' },
  { width: 225, height: 226, img: '/assets/falling-blocks/24.svg', url: '', tooltipText: 'Квесты, квизы, игры и интерактивы — развлекайся и побеждай' },
  { width: 177, height: 179, img: '/assets/falling-blocks/19.svg', url: '', tooltipText: 'Уникальные задания и призы для всех' },
  { width: 233, height: 214, img: '/assets/falling-blocks/26.svg', url: '', tooltipText: 'Интересные дискуссии с экспертами разных индустрий' },
] as Shape[];

const scrollToTickets = () => {
  commonStore.isOpenMenu = false;
  document.getElementById('tickets')?.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
  });
};

const mainTicketsBtnHandler = () => {
  sendAnalytics('fest_click');
  scrollToTickets();
};

const handleScroll = () => {
  if (ticketsSection.value) {
    const targetPosition = ticketsSection.value.getBoundingClientRect().bottom;
    const windowHeight = window.innerHeight;

    showButton.value = targetPosition <= windowHeight;
  }
};

onMounted(() => {
  window.addEventListener('scroll', handleScroll);
});

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
});
</script>

<style scoped lang="postcss">
.index-page {
  &__hero {
    background-color: var(--blue-color);
  }
  &__cities {
    background-color: var(--black-color);
  }
  &__headliners {
    margin-top: 20px;
  }
  &__community,
  &__falling-blocks,
  &__broadcast,
  &__app-links{
    margin-top: 144px;
  }

  @media screen and (max-width: 767px) {
    &__community,
    &__falling-blocks,
    &__broadcast {
      margin-top: 96px;
    }
    &__app-links {
      margin-top: 48px;
    }
  }
}

.headliners-community-wrapper {
  position: relative;

  &__video {
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    height: 1000px;
    object-fit: fill;
    z-index: -1;
  }
}
</style>
