<template>
  <div class="o-headliners ">
    <div class="o-headliners__title global-padding">
      <AText
        tag="h2"
        :size-lg="{ size: 50, line: 46.5 }"
        :size-sm="{ size: 32, line: 34 }"
      >
        Хедлайнеры
      </AText>
    </div>
    <ATabs v-if="data" class="o-headliners__tabs global-padding">
      <ATab :data-length="music?.length" title="Артисты" active>
        <div v-if="music?.length" class="o-headliners__artists">
          <NuxtLink
            v-for="i in music.slice(0, 15)"
            :key="i.id"
            :to="`/artist/${i.id}`"
          >
            <APhoto
              :image-url="i.image_preview"
              :text="i.name"
              :width="imgSize"
              :height="imgSize"
            />
          </NuxtLink>
        </div>
      </ATab>
      <ATab :data-length="influence?.length" title="Инфлюенсеры">
        <div v-if="influence?.length" class="o-headliners__artists">
          <NuxtLink
            v-for="i in influence.slice(0, 15)"
            :key="i.id"
            :to="`/artist/${i.id}`"
          >
            <APhoto
              :image-url="i.image_preview"
              :text="i.name"
              :width="imgSize"
              :height="imgSize"
            />
          </NuxtLink>
        </div>
      </ATab>
      <ATab :data-length="lecture?.length" title="Спикеры">
        <div v-if="lecture?.length" class="o-headliners__artists">
          <NuxtLink
            v-for="i in lecture.slice(0, 15)"
            :key="i.id"
            :to="`/artist/${i.id}`"
          >
            <APhoto
              :image-url="i.image_preview"
              :text="i.name"
              :width="imgSize"
              :height="imgSize"
            />
          </NuxtLink>
        </div>
      </ATab>
    </ATabs>
  </div>
</template>

<script setup lang="ts">
import ATabs from '~/components/atoms/tabs/a-tabs.vue';
import ATab from '~/components/atoms/tabs/a-tab.vue';
import AText from '~/components/atoms/text/a-text.vue';
import APhoto from '~/components/atoms/photo/a-photo.vue';
import type { IArtist } from '~/types/api';

interface Props {
  data?: IArtist[],
}

const props = defineProps<Props>();

const music = computed(() => props.data?.filter(artist => artist.subtype === 'music'));
const influence = computed(() => props.data?.filter(artist => artist.subtype === 'influence'));
const lecture = computed(() => props.data?.filter(artist => artist.subtype === 'lecture'));

const device = useDevice();

const isMobile = computed(() => device.isMobile.value);

const imgSize = computed(() => isMobile.value ? 151 : 230);
</script>

<style scoped lang="postcss" src="./style.css" />
