<template>
  <div class="o-broadcast global-padding">
    <div class="o-broadcast__title">
      <AText
        tag="h2"
        :size-lg="{ size: 50, line: 46.5 }"
        :size-sm="{ size: 32, line: 30 }"
      >
        СМОТРИ В ПРЯМОМ ЭФИРЕ<br>19 и 20 июля 2025 в 12:00
      </AText>
    </div>
    <div class="o-broadcast__text">
      <AText
        :size-lg="{ size: 18, line: 20 }"
        :size-sm="{ size: 18, line: 20 }"
        font-family="vk"
        weight="regular"
        :uppercase="false"
      >
        Делимся атмосферой фестиваля со всей страной! Вас ждут трансляции с Синей и Белой сцен в Москве, а также эксклюзивные включения со звёздами.
      </AText>
    </div>
    <div class="o-broadcast__video">
      <!--      <video-->
      <!--        ref="video"-->
      <!--        muted-->
      <!--        playsinline-->
      <!--        controls-->
      <!--        loop-->
      <!--        poster="/assets/posters/video-broadcast.webp"-->
      <!--      >-->
      <!--        <source src="/assets/videos/video-broadcast.mp4" type="video/mp4">-->
      <!--      </video>-->

      <template v-if="!isMobile">
        <ClientOnly>
          <iframe
            class="video"
            src="https://vk.com/video_ext.php?oid=-86529522&id=456243286&hd=3&autoplay=0&muted=1"
            width="1280"
            height="720"
            allow="muted; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;"
            frameborder="0"
            allowfullscreen
            title="Видеотрансляция с VK"
            loading="lazy"
          />
        </ClientOnly>
      </template>

      <AButtonWrapper
        class="o-broadcast__play-btn"
        background-url="/assets/backgrounds/btn-dwnld.svg"
        :width="225"
        @click="handlePlayBtn"
      >
        <AText
          tag="span"
          style="padding-top: 10px"
          :size-lg="{ size: 15, line: 15 }"
          :size-sm="{ size: 15, line: 15 }"
        >
          <NuxtImg class="o-broadcast__play-btn-icon" :width="13" loading="lazy" src="/assets/icons/play.svg" alt="play" />
          Смотреть
        </AText>
      </AButtonWrapper>

      <!--      <AText-->
      <!--        class="o-broadcast__views"-->
      <!--        :size-lg="{ size: 12, line: 16 }"-->
      <!--        :size-sm="{ size: 12, line: 16 }"-->
      <!--        font-family="vk"-->
      <!--        weight="regular"-->
      <!--        :uppercase="false"-->
      <!--      >-->
      <!--        <NuxtImg class="o-broadcast__views-icon" loading="lazy" :width="13" src="/assets/icons/eye.svg" alt="eye" />-->
      <!--        12 492 смотрят сейчас-->
      <!--      </AText>-->
    </div>
  </div>
</template>

<script setup lang="ts">
import AText from '~/components/atoms/text/a-text.vue';
import AButtonWrapper from '~/components/atoms/button-wrapper/a-button-wrapper.vue';

const device = useDevice();

const isMobile = computed(() => device.isMobile.value);

const handlePlayBtn = () => {
  window.open('https://vk.com/video_ext.php?oid=-86529522&id=456243286&hd=3&autoplay=1', '_blank');
};
</script>

<style scoped lang="postcss" src="./style.css" />
